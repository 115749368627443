.sign-box {
  width: 50%;
  margin: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 40px;
}
.forms form div {
  margin: 10px 0;
}
.forms form .flex input {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.forms p {
  font-size: 13px;
}
.forms form label {
  margin: 8px;
}
.forms form span {
  margin-top: 10px;
  color: crimson;
  font-size: 13px;
}
.forms form button {
  width: 100%;
  margin: 20px 0;
}
.forms form a {
  color: blue;
  font-size: 13px;
}

/*----------show-data---------*/
.show-data h1 {
  color: #31b675;
  margin: 0 0 20px 0;
}
.show-data h3 {
  display: flex;
}
.show-data h3 p {
  font-weight: 400;
  margin-left: 5px;
}
.show-data .sign-box {
  margin-top: 50px;
}
/*----------show-data---------*/
@media screen and (max-width: 768px) {
  .sign-box {
    width: 80%;
  }
}
